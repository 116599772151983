<template>
  <section class="">
    <partner-create
      v-model="partner"
      title="Yangi Hamkor"
      :img="img"
    ></partner-create>
    <div class="container">
      <a-button
        class="bg-success text-white ml-md-2 ml-lg-4"
        @click="createPartner"
      >
        Partner qo'shish
      </a-button>
    </div>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos partner link va rasmni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import PartnerCreate from '../../components/Partner/PartnerCreate.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    PartnerCreate,
  },
  data() {
    return {
      img: false,
      inValid: false,
      partner: {
        url: '',
        image: '',
      },
    };
  },

  methods: {
    ...mapActions({
      postPartner: 'partner/postPartner',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },

    createPartner() {
      const obj = {
        url: this.partner.url,
        image: this.partner.image.files,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }
      this.postPartner(obj)
        .then(() => {
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Partner qo'shildi :)",
          });
          this.partner = {
            url: '',
            image: '',
          };
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Iltimos yaroqli link kiriting',
          });
        });
    },
  },
};
</script>
